/*
  Theme Name: Avilon
  Theme URL: https://bootstrapmade.com/avilon-bootstrap-landing-page-template/
  Author: BootstrapMade.com
  License: https://bootstrapmade.com/license/
*/
/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
body {
  background: #fff;
  color: #666666;
  font-family: "Open Sans", sans-serif;
  overflow-x: hidden;
  text-align: justify;
}

a {
  color: #5c9cd6;
  transition: 0.5s;
}

a:hover, a:active, a:focus {
  color: #1dc9ce;
  outline: none;
  text-decoration: none;
}

p {
  padding: 0;
  margin: 0 0 30px 0;
}



h1, h2, h3, h4, h5, h6 {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  margin: 0 0 20px 0;
  padding: 0;
}

/* Back to top button */
.back-to-top {
  position: fixed;
  display: none;
  background: #3e678e;
  /* background: linear-gradient(45deg, #0B4FC7, #5c9cd6); */
  color: #fff;
  padding: 2px 20px 8px 20px;
  font-size: 16px;
  border-radius: 4px 4px 0 0;
  right: 15px;
  bottom: 0;
  transition: none;
}

.back-to-top:focus {
  background: linear-gradient(45deg, #4e81b1, #4e81b1);
  color: #fff;
  outline: none;
}

.back-to-top:hover {
  background: #5c9cd6;
  color: #fff;
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
  padding: 30px 0;
  height: 92px;
  position: sticky;
  left: 0;
  top: 0;
  right: 0;
  transition: all 0.5s;
  z-index: 997;
  background: #fff;
}

#header #logo {
  float: left;
}

#header #logo h1 {
  font-size: 36px;
  margin: -4px 0 0 0;
  padding: 0;
  line-height: 1;
  display: inline-block;
  /* font-family: "Open Sans", sans-serif; */
  font-weight: 300;
  letter-spacing: 3px;
  /* text-transform: uppercase; */
}

#header #logo h1 a, #header #logo h1 a:hover {
  color: #fff;
}

#header #logo img {
  padding: 0;
  margin: 0;
}

@media (max-width: 768px) {
  #header #logo h1 {
    font-size: 28px;
    margin-top: 0;
  }
  #header #logo img {
    max-height: 40px;
  }
}

#header.header-fixed {
  background: #fff; /* Change this to #fff to make the header background white */
  padding: 20px 0;
  height: 72px;
  transition: all 0.5s;
}

/*--------------------------------------------------------------
# Intro Section
--------------------------------------------------------------*/
#intro {
  width: 100%;
  height: 100vh;
  /* background: linear-gradient(45deg, rgba(176, 196, 222, 0.8), rgb(176, 196, 222, 0.8)), url("../img/data-bg.jpg") center top no-repeat; */
  background:  url("../img/data-bg.jpg") center top no-repeat;
  background-size: cover;
  position: relative;
}

@media (min-width: 1024px) {
  #intro {
    background-attachment: fixed;
  }
}

#intro .intro-text {
  position: absolute;
  left: 0;
  top: 60px;
  right: 0;
  height: calc(50% - 60px);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
}

#intro h2 {
  margin: 30px 0 10px 0;
  padding: 0 15px;
  font-size: 48px;
  font-weight: 400;
  line-height: 56px;
  color: #fff;
}

@media (max-width: 768px) {
  #intro h2 {
    font-size: 28px;
    line-height: 36px;
  }
}

#intro p {
  color: black;
  margin-bottom: 20px;
  padding: 0 15px;
  /* line-height: 40px; */
  /* font-size: 24px; */
}

#intro .white-hr{
  border-color:black;
}

@media (max-width: 768px) {
  #intro p {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 20px;
  }
}

#intro .btn-get-started {
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 12px 32px;
  border-radius: 25px;
  transition: all 0.3s ease;
  margin: 10px;
  color: white;
  border: none;
  background: linear-gradient(135deg, #305275, #4b7da8);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

#intro .btn-get-started:hover {
  color: white;
  background: linear-gradient(135deg, #4b7da8, #305275);
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

#intro .product-screens {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  text-align: center;
  width: 100%;
  height: 50%;
}

#intro .product-screens img {
  box-shadow: 0px -2px 19px 4px rgba(0, 0, 0, 0.29);
}

#intro .product-screens .product-screen-1 {
  position: absolute;
  z-index: 30;
  left: calc(50% + 54px);
  bottom: 0;
  top: 30px;
}

#intro .product-screens .product-screen-2 {
  position: absolute;
  z-index: 20;
  left: calc(50% - 154px);
  bottom: 0;
  top: 90px;
}

#intro .product-screens .product-screen-3 {
  position: absolute;
  z-index: 10;
  left: calc(50% - 374px);
  bottom: 0;
  top: 150px;
}

@media (max-width: 767px) {
  #intro .product-screens .product-screen-1 {
    position: static;
    padding-top: 30px;
  }
  #intro .product-screens .product-screen-2, #intro .product-screens .product-screen-3 {
    display: none;
  }

  .product-screens{
    display:none;
  }
}

.intro-words{
  margin-top: 0px;
  transform: rotate(0deg);
  /* font-style: italic; */
  font-size: 20px;
}
.intro-words >p{
  /* text-transform: uppercase; */
  color: black;
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/* Nav Menu Essentials */
.nav-menu, .nav-menu * {
  margin: 0;
  padding: 0;
  list-style: none;
}

.nav-menu ul {
  position: absolute;
  display: none;
  top: 100%;
  left: 0;
  z-index: 99;
}

.nav-menu li {
  position: relative;
  white-space: nowrap;
}

.nav-menu > li {
  float: left;
}

.nav-menu li:hover > ul,
.nav-menu li.sfHover > ul {
  display: block;
}

.nav-menu ul ul {
  top: 0;
  left: 100%;
}

.nav-menu ul li {
  min-width: 180px;
}

/* Nav Menu Arrows */
.sf-arrows .sf-with-ul {
  padding-right: 30px;
}

.sf-arrows .sf-with-ul:after {
  content: "\f107";
  position: absolute;
  right: 15px;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
}

.sf-arrows ul .sf-with-ul:after {
  content: "\f105";
}

/* Nav Meu Container */
#nav-menu-container {
  float: right;
  margin: 0;
}

@media (max-width: 768px) {
  #nav-menu-container {
    display: none;
  }
}

/* Nav Meu Styling */
.nav-menu a {
  padding: 0 8px 10px 8px;
  text-decoration: none;
  display: inline-block;
  color: #325272;
  /* font-family: "Open Sans", sans-serif; */
  font-weight: 400;
  font-size: 14px;
  outline: none;
}

.nav-menu > li {
  margin-left: 10px;
}

.nav-menu ul {
  margin: 4px 0 0 0;
  padding: 10px;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  background: #fff;
}

.nav-menu ul li {
  transition: 0.3s;
}

.nav-menu ul li a {
  padding: 10px;
  color: #333;
  transition: 0.3s;
  display: block;
  font-size: 13px;
  text-transform: none;
}

.nav-menu ul li:hover > a {
  color: #325272;
  font-weight: bold;
}

.nav-menu ul ul {
  margin: 0;
}

/* Mobile Nav Toggle */
#mobile-nav-toggle {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 999;
  margin: 20px 20px 0 0;
  border: 0;
  background: none;
  font-size: 24px;
  display: none;
  transition: all 0.4s;
  outline: none;
  cursor: pointer;
}

#mobile-nav-toggle i {
  color: #fff;
}

@media (max-width: 768px) {
  #mobile-nav-toggle {
    display: inline;
  }
}

/* Mobile Nav Styling */
#mobile-nav {
  position: fixed;
  top: 0;
  padding-top: 18px;
  bottom: 0;
  z-index: 998;
  background: rgba(52, 59, 64, 0.9);
  left: -260px;
  width: 260px;
  overflow-y: auto;
  transition: 0.4s;
}

#mobile-nav ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

#mobile-nav ul li {
  position: relative;
}

#mobile-nav ul li a {
  color: #fff;
  font-size: 16px;
  overflow: hidden;
  padding: 10px 22px 10px 15px;
  position: relative;
  text-decoration: none;
  width: 100%;
  display: block;
  outline: none;
}

#mobile-nav ul li a:hover {
  color: #fff;
}

#mobile-nav ul li li {
  padding-left: 30px;
}

#mobile-nav ul .menu-has-children i {
  position: absolute;
  right: 0;
  z-index: 99;
  padding: 15px;
  cursor: pointer;
  color: #fff;
}

#mobile-nav ul .menu-has-children i.fa-chevron-up {
  color: #5c9cd6;
}

#mobile-nav ul .menu-item-active {
  color: #5c9cd6;
}

#mobile-body-overly {
  width: 100%;
  height: 100%;
  z-index: 997;
  top: 0;
  left: 0;
  position: fixed;
  background: rgba(52, 59, 64, 0.9);
  display: none;
}

/* Mobile Nav body classes */
body.mobile-nav-active {
  overflow: hidden;
}

body.mobile-nav-active #mobile-nav {
  left: 0;
}

body.mobile-nav-active #mobile-nav-toggle {
  color: #fff;
}

/*--------------------------------------------------------------
# Sections
--------------------------------------------------------------*/
/* Sections Header
--------------------------------*/
.section-header .section-title {
  font-size: 32px;
  color: #111;
  text-align: center;
  font-weight: bold;
}

.section-header .section-description {
  text-align: center;
  padding-bottom: 15px;
  color: #777;
  font-style: italic;
}

.section-header .section-divider {
  display: block;
  width: 60px;
  height: 3px;
  background: #5c9cd6;
  background: linear-gradient(0deg, #5c9cd6 0%, #55fabe 100%);
  margin: 0 auto;
  margin-bottom: 20px;
}

hr {
  width: 100px;
  border-bottom: solid;
  border-bottom-width: 3px;
  border-bottom-color: #5c9cd6;
  margin-bottom: 40px;
}

@media (min-width: 576px){
  .white-hr{
    width: 400px;
    border-bottom: solid;
    border-bottom-width: 3px;
    border-bottom-color: white;
    margin-bottom: 40px;
  }
}

@media (max-width: 576px){
  .white-hr{
    width: 340px;
    border-bottom: solid;
    border-bottom-width: 3px;
    border-bottom-color: white;
    margin-bottom: 40px;
  }
}

/* Section with background
--------------------------------*/
.section-bg {
  background: #edf2f7;
}

/* About Us Section
--------------------------------*/
#about {
  padding: 60px 0;
  overflow: hidden;
}

#about .about-img {
  /* height: 480px; */
  overflow: hidden;
}

#about .about-img img {
  /* margin-left: -15px; */
  max-width: 100%;
}

@media (max-width: 768px) {
  #about .about-img {
    height: auto;
  }
  #about .about-img img {
    margin-left: 0;
    padding-bottom: 30px;
  }
}

#about .content .h2 {
  color: #333;
  font-weight: 300;
  font-size: 24px;
}

#about .content h3 {
  color: #777;
  font-weight: 300;
  font-size: 18px;
  line-height: 26px;
  font-style: italic;
}

#about .content p {
  line-height: 26px;
}

#about .content p:last-child {
  margin-bottom: 0;
}

#about .content i {
  font-size: 20px;
  padding-right: 4px;
  color: #5c9cd6;
}

#about .content ul {
  list-style: none;
  padding: 0;
}

#about .content ul li {
  padding-bottom: 10px;
}

/* Product Featuress Section
--------------------------------*/
#features {
  /* background: #fff; */
  padding: 60px 0 0 0;
  overflow: hidden;
}

#features .features-img {
  text-align: center;
  padding-top: 20px;
  padding-bottom: 60px;
}

@media (min-width: 769px) {
  #features .features-img {
    /* padding-top: 120px; */
    /* margin-top: -100px; */
     
  }
}

#features .features-img img {
  max-width: 100%;
}

#features .box {
  margin-bottom: 15px;
  text-align: center;
}

#features .icon {
  margin-bottom: 10px;
}

#features .icon i {
  color: #666666;
  font-size: 40px;
  transition: 0.5s;
}

#features .icon i:before {
  background: #5c9cd6;
  /* background: linear-gradient(45deg, #5c9cd6 0%, #55fabe 100%); */
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#features .title {
  font-weight: 300;
  margin-bottom: 15px;
  font-size: 22px;
}

#features .title a {
  color: #111;
}

#features .description {
  font-size: 1rem;
  line-height: 24px;
  margin-bottom: 10px;
}

#features .section-description {
  padding-bottom: 10px;
}

/* Product Advanced Featuress Section
--------------------------------*/
#advanced-features {
  overflow: hidden;
}

#advanced-features .features-row {
  padding: 60px 0 30px 0;
}

#advanced-features h2 {
  font-size: 26px;
  font-weight: 700;
  color: #000;
}

#advanced-features h3 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  font-style: italic;
  color: #999;
}

#advanced-features p {
  line-height: 24px;
  color: #777;
  margin-bottom: 30px;
}

#advanced-features i {
  color: #666666;
  font-size: 64px;
  transition: 0.5s;
  float: left;
  padding: 0 15px 0px 0;
  line-height: 1;
}

#advanced-features i:before {
  background: #5c9cd6;
  /* background: linear-gradient(45deg, #5c9cd6 0%, #55fabe 100%); */
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#advanced-features .advanced-feature-img-right {
  max-width: 100%;
  float: right;
  padding: 0 0 30px 30px;
}

#advanced-features .advanced-feature-img-left {
  width: 100%;
  float: left;
  /* padding: 0 30px 30px 0; */
}

@media (max-width: 768px) {
  #advanced-features .advanced-feature-img-right, #advanced-features .advanced-feature-img-left {
    max-width: 50%;
  }
}

@media (max-width: 767px) {
  #advanced-features .advanced-feature-img-right, #advanced-features .advanced-feature-img-left {
    max-width: 100%;
    float: none;
    padding: 0 0 30px 0;
  }
}

/* Call To Action Section
--------------------------------*/
#call-to-action {
  overflow: hidden;
  background-color: #3e678e;
  background-size: cover;
  padding: 80px 0;
}

#call-to-action .cta-title {
  color: #fff;
  font-size: 28px;
  font-weight: 700;
}

#call-to-action .cta-text {
  color: #fff;
}

@media (min-width: 769px) {
  #call-to-action .cta-btn-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

#call-to-action .cta-btn {
  /* font-family: "Open Sans", sans-serif; */
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 8px 30px;
  border-radius: 25px;
  transition: background 0.5s;
  margin: 10px;
  border: 2px solid #fff;
  color: #fff;
}

#call-to-action .cta-btn:hover {
  background: #5c9cd6;
  border: 2px solid #5c9cd6;
}

/* More Features Section
--------------------------------*/
#more-features {
  padding: 60px 0 60px 0;
  overflow: hidden;
}

#more-features .box {
  padding: 40px;
  margin-bottom: 30px;
  box-shadow: 0px 0px 30px rgba(73, 78, 92, 0.15);
  background: #fff;
  transition: 0.4s;
}

#more-features .icon {
  float: left;
}

#more-features .icon i {
  color: #666666;
  font-size: 80px;
  transition: 0.5s;
  line-height: 0;
}

#more-features .icon i:before {
  background: #5c9cd6;
  /* background: linear-gradient(45deg, #5c9cd6 0%, #55fabe 100%); */
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#more-features h4 {
  /* margin-left: 100px; */
  text-align: center;
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 18px;
}

#more-features h4 a {
  color: #111;
}

#more-features p {
  /* font-size: 14px; */
  /* margin-left: 100px; */
  margin-bottom: 0;
  line-height: 24px;
}

@media (max-width: 767px) {
  #more-features .box {
    margin-bottom: 20px;
  }
  #more-features .icon {
    float: none;
    text-align: center;
    padding-bottom: 15px;
  }
  #more-features h4, #more-features p {
    margin-left: 0;
    text-align: center;
  }
}

.client-block{
  min-height: 370px;
}

/* Clients Section
--------------------------------*/
#clients {
  padding: 30px 0;
  /* background: #fff; */
  overflow: hidden;
}

#clients img {
  max-width: 100%;
  opacity: 0.5;
  transition: 0.3s;
  padding: 15px 0;
}

#clients img:hover {
  opacity: 1;
}

.flip-card-back > .description{
  color:black;
}

.flip-card-back > .description:hover{
  color:#1565C0;
}

/* The flip card container - set the width and height to whatever you want. We have added the border property to demonstrate that the flip itself goes out of the box on hover (remove perspective if you don't want the 3D effect */
.flip-card {
  background-color: transparent;
  /* width: 300px;
  height: 200px; */
  border: 1px solid #f1f1f1;
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
}

/* This container is needed to position the front and back side */
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 330px;
  /* text-align: center; */
  transition: transform 0.8s;
  transform-style: preserve-3d;
  padding-top: 40px;
  margin-bottom: 30px;
  box-shadow: 0px 0px 30px rgba(73, 78, 92, 0.15); 
  background-color: white;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

/* Position the front and back side */
.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  /* box-shadow: 0px 0px 30px rgba(73, 78, 92, 0.15);  */
  background-color: white;
  color: black;
}

/* Style the front side (fallback if image is missing) */
.flip-card-front {
  text-align: center;
  height:100%;
  overflow:auto;
  filter: grayscale(100%);
}

/* Style the back side */
.flip-card-back {
  transform: rotateY(180deg);
  padding-left:40px;
  padding-right:40px;
}

/* Pricing Section
--------------------------------*/
#pricing {
  padding: 60px 0 60px 0;
  overflow: hidden;
}

#pricing .box {
  padding: 40px;
  margin-bottom: 30px;
  box-shadow: 0px 0px 30px rgba(73, 78, 92, 0.15);
  background: #fff;
  text-align: center;
}

#pricing h3 {
  font-weight: 400;
  margin-bottom: 15px;
  font-size: 28px;
}

#pricing h4 {
  font-size: 46px;
  color: #5c9cd6;
  font-weight: 300;
}

#pricing h4 sup {
  font-size: 20px;
  top: -20px;
}

#pricing h4 span {
  color: #bababa;
  font-size: 20px;
}

#pricing ul {
  padding: 0;
  list-style: none;
  color: #999;
  text-align: left;
  line-height: 20px;
}

#pricing ul li {
  padding-bottom: 12px;
}

#pricing ul i {
  color: #5c9cd6;
  font-size: 18px;
  padding-right: 4px;
}

#pricing .get-started-btn {
  background: #515e61;
  display: inline-block;
  padding: 6px 30px;
  border-radius: 20px;
  color: #fff;
  transition: none;
  font-size: 14px;
  font-weight: 400;
  /* font-family: "Open Sans", sans-serif; */
}

#pricing .featured {
  border: 2px solid #5c9cd6;
}

#pricing .featured .get-started-btn {
  background: linear-gradient(45deg, #0B4FC7, #5c9cd6);
}

/* Frequently Asked Questions Section
--------------------------------*/
#faq {
  padding: 60px 0;
  overflow: hidden;
}

#faq #faq-list {
  padding: 0;
  list-style: none;
}

#faq #faq-list li {
  border-bottom: 1px solid #ddd;
}

#faq #faq-list a {
  padding: 18px 0;
  display: block;
  position: relative;
  /* font-family: "Open Sans", sans-serif; */
  font-size: 22px;
  line-height: 1;
  font-weight: 300;
  padding-right: 20px;
}

#faq #faq-list i {
  font-size: 24px;
  position: absolute;
  right: 0;
  top: 16px;
}

#faq #faq-list p {
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  #faq #faq-list a {
    font-size: 18px;
  }
  #faq #faq-list i {
    top: 13px;
  }
}

#faq #faq-list a.collapse {
  color: #5c9cd6;
}

#faq #faq-list a.collapsed {
  color: #000;
}

#faq #faq-list a.collapsed i::before {
  content: "\f2c7" !important;
}

/* Our Team Section
--------------------------------*/
#team {
  padding: 60px 0;
  overflow: hidden;
}

#team .member {
  text-align: center;
  margin-bottom: 20px;
}

#team .member .pic {
  margin-bottom: 15px;
  overflow: hidden;
  height: 260px;
}

#team .member .pic img {
  max-width: 100%;
}

#team .member h4 {
  font-weight: 700;
  margin-bottom: 2px;
  font-size: 18px;
}

#team .member span {
  font-style: italic;
  display: block;
  font-size: 13px;
}

#team .member .social {
  margin-top: 15px;
}

#team .member .social a {
  color: #b3b3b3;
}

#team .member .social a:hover {
  color: #5c9cd6;
}

#team .member .social i {
  font-size: 18px;
  margin: 0 2px;
}

/* Gallery Section
--------------------------------*/
#gallery {
  background: #fff;
  padding: 60px 0 0 0;
  overflow: hidden;
}

#gallery .container-fluid {
  padding: 0px;
}

#gallery .gallery-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  -webkit-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
}

#gallery .gallery-item {
  overflow: hidden;
  position: relative;
  padding: 0;
  vertical-align: middle;
  text-align: center;
}

#gallery .gallery-item img {
  -webkit-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
  width: 100%;
}

#gallery .gallery-item:hover img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

#gallery .gallery-item:hover .gallery-overlay {
  opacity: 1;
  background: rgba(0, 0, 0, 0.7);
}

/* Contact Section
--------------------------------*/
#contact {
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
  padding: 60px 0;
  overflow: hidden;
}

#contact .contact-about h3 {
  font-size: 36px;
  margin: 0 0 10px 0;
  padding: 0;
  line-height: 1;
  /* font-family: "Open Sans", sans-serif; */
  font-weight: 300;
  letter-spacing: 3px;
  text-transform: uppercase;
  color: #5c9cd6;
}

#contact .contact-about p {
  font-size: 1rem;
  line-height: 24px;
  /* font-family: "Open Sans", sans-serif; */
  color: #888;
}

#contact .social-links {
  padding-bottom: 20px;
}

#contact .social-links a {
  font-size: 18px;
  display: inline-block;
  background: #fff;
  color: #5c9cd6;
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
  border: 1px solid #5c9cd6;
}

#contact .social-links a:hover {
  background: #5c9cd6;
  color: #fff;
}

#contact .info {
  color: #333333;
}

#contact .info i {
  font-size: 32px;
  color: #5c9cd6;
  float: left;
  line-height: 1;
}

#contact .info p {
  padding: 0 0 10px 42px;
  line-height: 28px;
  font-size: 14px;
}

#contact .form #sendmessage {
  color: #5c9cd6;
  border: 1px solid #5c9cd6;
  display: none;
  text-align: center;
  padding: 15px;
  font-weight: 600;
  margin-bottom: 15px;
}

#contact .form #errormessage {
  color: red;
  display: none;
  border: 1px solid red;
  text-align: center;
  padding: 15px;
  font-weight: 600;
  margin-bottom: 15px;
}

#contact .form #sendmessage.show, #contact .form #errormessage.show, #contact .form .show {
  display: block;
}

#contact .form .validation {
  color: red;
  display: none;
  margin: 0 0 20px;
  font-weight: 400;
  font-size: 13px;
}

#contact .form input, #contact .form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
}

#contact .form button[type="submit"], .btn-round-outline {
  /* background: linear-gradient(45deg, #0B4FC7, #5c9cd6); */
  background-color: white;
  border: 2px solid #5c9cd6;
  border-radius: 20px;
  padding: 8px 30px;
  color: #5c9cd6;
  float:right;
}

#contact .form button[type="submit"]:hover, .btn-round-outline:hover {
  /* background: linear-gradient(45deg, #0B4FC7, #5c9cd6); */
  background-color: #5c9cd6;
  border: 2px solid #5c9cd6;
  border-radius: 20px;
  padding: 8px 30px;
  color: white;
  float:right;
}

#contact .form button[type="submit"]:hover, .btn-round-outline:hover {
  cursor: pointer;
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#footer {
  background: #fff;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
  padding: 30px 0;
  color: #333;
  font-size: 14px;
}

#footer .credits {
  font-size: 13px;
  color: #888;
}

#footer .footer-links a {
  color: #666;
  padding-left: 15px;
}

#footer .footer-links a:first-child {
  padding-left: 0;
}

#footer .footer-links a:hover {
  color: #5c9cd6;
}

.AutoSenseBlue{
	color: #5c9cd6;
}

/*social media button styles*/
.icon-bar {
  position: fixed;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index:9;
}

.icon-bar a {
  display: block;
  text-align: center;
  padding: 16px;
  transition: all 0.3s ease;
  color: white;
  font-size: 20px;
}

.icon-bar a:hover {
  background-color: #000;
}

.facebook {
  background: #3B5998;
  color: white;
}

.twitter {
  background: #55ACEE;
  color: white;
}

.google {
  background: #dd4b39;
  color: white;
}

.linkedin {
  background: #007bb5;
  color: white;
}

.youtube {
  background: #bb0000;
  color: white;
}

.content {
  margin-left: 75px;
  font-size: 30px;
}

@media(max-width:350px){
  #logo{
    max-width:290px;
    
  }

  .img-partner{
    width:240px !important;
    height:280px;
  }
}

/* @media(min-width:350px){
  #logo{
    height:150px;
    
  }
} */

.card-text{
  text-align: left !important;
}
@media (min-width: 992px){
  .card{
    /* height:100%; */
    height: 400px;
    border-radius: 0;
  }
  
  .card-body{
    height:154px;
  }
}

@media (max-width: 992px) and (min-width: 576px){
  .card, .card-body{
    text-align: left !important;
  }

  .card-footer{
    height:95px;
  }

  .card{
    /* height:100%; */
    min-height: 645px;
    border-radius: 0;
  }
  
  .card-body{
    height:491px;
  }
}

@media (max-width: 576px){
  .card-footer{
    height:65px;
  }
}

.card-image > img{
  height:140px;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
}
.carousel-control-next-icon, .carousel-control-prev-icon{
  width:100%;
}

.carousel-control-next, .carousel-control-prev {
  width:5%;
}

#icon{
  background-color: #3e678e;
  color:white;
  padding: 60px 0;
}
#icon > .container>.section-header>.section-title{
  color:white;
}
.icon{
  color: white;
  font-size: 40px;
  transition: 0.5s;
  margin-bottom: 20px;
}

.icon-line{
  color:white;
  font-size: 40px;
  border-color: white;
  width: 45px;
  margin-top:5px;
  margin-bottom:5px;
}

.icon-spin{
  font-size: 48px;
  margin-bottom: 0px;
}

/*
tinting images
*/

.image-tint {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: calc(100% - 30px);
  background: rgba(176, 196, 222, 0.42);
  /* background: rgba(0,255,255, 0.5); */
  /* linear-gradient(45deg, , rgb(176, 196, 222, 0.8)), */
  transition: all .3s linear;
  margin-left: 15px;
  /* padding-right: 15px; */
  z-index: 1007;
}
.image-tint:hover:before { 
  background: none;
}

#advanced-features .advanced-feature-img-right, .img-stretch{
  width: 100%;
  height: 100%;
  padding: 0px;
  margin: 0px;
}

.typed{
  color: #5899D3;
  font-weight: bolder;
}

.intro-words.font-weight-bold{
  font-size: 40px;
}

/*text sliding animation*/
@media (max-width: 768px) {
  .content {
    font-size: 18px;
    line-height: 24px;
  }

  .content__container {
    font-weight: 600;
    overflow: hidden;
    height: 24px;
    padding: 0 40px;
  }

  .content__container__list {
    margin-top: 0;
    padding-left: 100px;
    text-align: left;
    list-style: none;
    color:#5899D3;
    -webkit-animation-name: change;
    -webkit-animation-duration: 6s;
    -webkit-animation-iteration-count: infinite;
    animation-name: change;
    animation-duration: 6s;
    animation-iteration-count: infinite;
  }
  
}

@media (min-width: 768px) {

  .content {
    font-size: 35px;
    line-height: 40px;
  }

  .content__container {
    font-weight: 600;
    overflow: hidden;
    height: 40px;
    padding: 0 40px;
  }

  .content__container__list {
    margin-top: 0;
    padding-left: 165px;
    text-align: left;
    list-style: none;
    color:#5899D3;
    -webkit-animation-name: change;
    -webkit-animation-duration: 6s;
    -webkit-animation-iteration-count: infinite;
    animation-name: change;
    animation-duration: 6s;
    animation-iteration-count: infinite;
  }
  
}

.content__container__text {
	display: inline;
	float: left;
	margin: 0;
}


.content__container__list__item {
	/* line-height: 40px; */
	margin: 0;
}

@-webkit-keyframes change {
	0% {
		transform: translate3d(0, 0, 0);
	}

	25.01% {
		transform: translate3d(0, -20%, 0);
	}

	50.01% {
		transform: translate3d(0, -40%, 0);
	}

	75.01% {
		transform: translate3d(0, -60%, 0);
  }
  
  100%{
    transform: translate3d(0, -80%, 0);
  }
}

@-o-keyframes change {
	0% {
		transform: translate3d(0, 0, 0);
	}

	25.01% {
		transform: translate3d(0, -20%, 0);
	}

	50.01% {
		transform: translate3d(0, -40%, 0);
	}

	75.01% {
		transform: translate3d(0, -60%, 0);
  }
  
  100%{
    transform: translate3d(0, -80%, 0);
  }
}

@-moz-keyframes change {
  0% {
		transform: translate3d(0, 0, 0);
	}

	25.01% {
		transform: translate3d(0, -20%, 0);
	}

	50.01% {
		transform: translate3d(0, -40%, 0);
	}

	75.01% {
		transform: translate3d(0, -60%, 0);
  }
  
  100%{
    transform: translate3d(0, -80%, 0);
  }
}



@keyframes change {
	0% {
		transform: translate3d(0, 0, 0);
	}

	25.01% {
		transform: translate3d(0, -20%, 0);
	}

	50.01% {
		transform: translate3d(0, -40%, 0);
	}

	75.01% {
		transform: translate3d(0, -60%, 0);
  }
  
  100%{
    transform: translate3d(0, -80%, 0);
  }
}